<template>
	<div class="main-content" 
        v-loading="loading"
        element-loading-text="上传中"
        element-loading-spinner="el-icon-loading">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分发放</div>
		</div>
        <div class="context_box">
            <div class="context_list"><span class="context_jifen">企业会员积分余额：</span><p>{{integralBalance}}</p></div>
            <div class="context_list" style="margin-bottom:20px;">
                <span class="context_jifen">积分名单：</span>
                 <el-upload
                 
                    :headers="headers"
                    action="api/platform/acc/pointsout/upload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess" 
					:before-upload="beforeUpload"
                    multiple
					type="primary"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    :file-list="fileList">
                    <el-button type="primary">上传</el-button>
                </el-upload>

                <p class="context_tip" v-if="file.length > 0">{{file}}</p>
                <span class="del" v-if="file.length > 0" @click="delFile">删除</span>
            </div>
            <div class="context_list"><span class="context_jifen"></span><p class="download" @click="download">下载文件模板</p></div>
            <el-button class="context_fafang" type="primary" @click="fafang">积分发放</el-button>
        </div>
	</div>
</template>

<script>
	import {
		pointsoutBalance,pointsoutFafang
	} from "@/api/platform/index";
	export default {
		name: "pointsout",
		data() {
			return {
				loading:false,
                integralBalance: '0.00',
				id:'',
                orderNo:'',
                placeTime:'',
                supplierOrderStatus:'',
                dinddjf:'',
                yunfjf:'',
                yingfjf:'',
                shifjf:'',
                receiverName:'',
                receiverAddress:'',
                receiverMobile:'',
                remarks:'',
                headers: {
                    Authorization: this.$store.state.user.Token,
                },
                fileList:[],
                file:'',
                fileName:''
			};
		},
		mounted() {
			let that = this;
			that.getBalance();
		},
		methods: {
			//获取列表
			getBalance() {
                let that = this;
				pointsoutBalance().then(res => {
                    if(res.code  == 200){
                        that.integralBalance = res.data.integralBalance;
                    }else{
                        that.$message.error(res.msg)
                    }
				})
			},
            
            // 文件校验
            beforeUpload(file) {
				// 文件类型
				const isType = file.type === 'application/vnd.ms-excel'
				const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				const fileType = isType || isTypeComputer
				if (!fileType) {
					this.$message.error('上传文件只能是xls/xlsx格式！')
				}
				// 文件大小限制为2M
				const fileLimit = file.size / 1024 / 1024 < 2
				if (!fileLimit) {
					this.$message.error('上传文件大小不超过2M！')
				}
				return fileType && fileLimit
			},

            // 上传成功
            handleAvatarSuccess(res, file) {
                let that = this;
				if(res.code == 200){
                    that.file = file.name;
                    that.fileName = res.data.fileName;
					that.$message.success('上传成功');
                }else{
					that.$message.error(res.msg);
				}
			},

            // 删除名单
            delFile(){
                let that = this;
                that.file = '';
                that.fileName = '';
                that.$message.success('删除成功')
            },

            // 下载模板
            download(){
                let that = this;
                that.$axios({
                    method:"get", 
                    url:"/api/platform/acc/pointsout/download", 
                    headers:{
                        Authorization: that.$store.state.user.Token,
                    },
                    responseType:'blob'
                }).then((res) => { 
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute(
                        'download',
                        "积分发放文件模板.xlsx"
                    )
                    document.body.appendChild(link)
                    link.click()
                })
            },

            // 积分发放
            fafang(){
                let that = this;
                if(that.fileName == ''){
                    that.$message.warning('请先上传积分名单')
                }else{
                    let data = {
                        fileName: that.fileName
                    }
                    pointsoutFafang(data).then(res => {
                        if(res.code == 200){
                            that.file = '';
                            that.fileName == '';
                            that.getBalance();
                            that.$message.success(res.msg);
                        }else{
                            that.file = '';
                            that.fileName == '';
                            that.$message.error(res.msg)
                        }
                    }).catch(e =>{
                        that.$message.error(e.msg)
                    })
                }
                
            },

		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:300px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.context_box{
    box-sizing: border-box;
    padding: 20px 20px;
    position: relative;
}
.context_list{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
    font-size: 16px;
    color: #333;
}
.context_jifen{
    text-align: right;
    width: 150px;
    margin-right: 10px;
}
.context_tip{
    font-size: 14px;
    color: #333;
    margin: 0 20px;
}
.del{
    font-size: 14px;
    color: blue;
    width: auto;
    cursor: pointer;
    margin-left: 20px;
}
.download{
    font-size: 14px;
    color: blue;
    width: auto;
    cursor: pointer;
}
.context_fafang{
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>
